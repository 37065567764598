let specialText = "";

function checkSpecialKey(str) {
  if (str === null || str === undefined || str === "") return true;
  const specialKey = `"[]%_!！~@'#￥%……&*（）：“；‘。’”+.,*-+|?？();、\\^<>{}=/$`;
  const special =
    "----|____|select|update|delete|insert|trancate|char|into|substr|ascii|declare|exec|count|master|into|drop|execute"; //某些企业的特殊要求，不需要的可删除
  const arry = special.split("|");
  for (let i = 0; i < str.length; i++) {
    if (specialKey.indexOf(str.substr(i, 1)) !== -1) {
      specialText = str.substr(i, 1);
      return false;
    }
  }
  for (let k = 0; k < arry.length; k++) {
    if (str.indexOf(arry[k]) !== -1) {
      specialText = arry[k];
      return false;
    }
  }
  specialText = "";
  return true;
}

// 特殊字符交验
export function validateSpecialKey(rule, value, callback) {
  if (!value) {
    callback();
  } else if (!checkSpecialKey(value)) {
    callback(new Error(`不能输入特殊字符 ${specialText}`));
  } else {
    callback();
  }
}
// 税号校验
export function validateTax(rule, value, callback) {
  const tax = /^[A-Z0-9]{15}$|^[A-Z0-9]{17}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}$/;
  if (!tax.test(value) && value !== "") {
    callback(new Error("请输入格式正确的单位税号"));
  } else {
    callback();
  }
};
// 统一社会信用代码校验
export function validateCode(rule, value, callback) {
  const reg =
    /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/;
  if (reg.test(value)) {
    callback();
  } else {
    return callback(new Error("请输入正确的统一社会信用代码"));
  }
};
// 手机固话校验
export function validatePhone(rule, value, callback) {
  const moblie =
    "^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-9])|(18[0-9])|166|198|199|191|195|193|(147))\\d{8}$";
  const tel = /^(\d{3,4}-)?\d{7,8}$/;
  if (!value) {
    callback();
  } else if (
    !new RegExp(moblie).test(value) &&
    !tel.test(value) &&
    value !== ""
  ) {
    callback(new Error("请输入格式正确的联系方式"));
  } else {
    callback();
  }
};
// 手机校验
export function checkPhone(rule, value, callback) {
  const reg =
    "^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-9])|(18[0-9])|166|198|199|191|195|193|(147))\\d{8}$";
  if (new RegExp(reg).test(value)) {
    callback();
  } else {
    return callback(new Error("请输入正确的手机号"));
  }
};
// 10位整数加4位小数校验
export function price(rule, value, callback) {
  if (!value) {
    callback();
  } else {
    const reg = /^[0-9]{1,10}([.][0-9]{1,4})?$/;
    if (reg.test(value)) {
      callback();
    } else {
      return callback(new Error("最多10位整数，4位小数"));
    }
  }
};
